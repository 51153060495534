import React from "react";
import PropTypes from "prop-types";

const Footer = ({ footerClass }) => (
	<div className={`defaultFooter ${footerClass}`}>
		{/* <p>
			View site source on{" "}
			<a
				href="https://github.com/rashmiap/personal-website-react"
				target="_blank"
			>
				Github
			</a>{" "}
			| Designer{" "}
			<a href="https://www.behance.net/ntshgdwn3596" target="_blank">
				Natasha G
			</a>
		</p> */}
		<p>
			© {new Date().getFullYear()}, Ryan D. Fisher. All rights reserved. 
			| For more information, click{" "} 
			{/* <a href="https://www.ryandouglasfisher.com/contact" target="_blank"> */}
			{/* <a to={\contact} exact={"true"} activeClassName="active"> */}
			<a href="\contact">
				here
			</a>
			{" "}to contact me
		</p>
	</div>
);
Footer.propTypes = {
	footerClass: PropTypes.string
};
export default Footer;
