import ryanicon from '../assets/images/xcf_files/small_plane_img.png'

export const aboutWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": "http://ryandougalsfisher.com",
    "name": "Ryan D. Fisher | Aerospace Engineer Portfolio",
    "author": {
        "@type": "Person",
        "name": "Ryan D. Fisher"
    }
}
export const siteMeta = {
    title: 'Ryan Fisher | Aerospace Engineer',
    favicon: ryanicon,
    author: "Ryan Douglas Fisher",
    description: "Thanks for visiting my portfolio. I am an active Aerospace Engineer in the field of both Air and Space Vehicles, pursing a dream of chaning the industry with innovative ideas and techniques. Feel free to look around and reach out if you have more questions regarding the previous works or potential future partnerships.",
    copyright: "Ryan d. Fisher (c) 2020",
    keywords: [
        "Ryan Douglas Fisher",
        "ryandouglasfisher",
        "ryan douglas fisher",
        "aerospace engineer",
        "aeronautical engineer",
        "systems engineer",
        "python",
        "Virginia Tech",
        "Lockheed Martin",
        "engineer",
        "aircraft",
        "satellites",
        "systems",
        "AIAA Winner",
        "aircraft engineer"
    ],
}
